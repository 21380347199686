<template>
  <div class="course-library-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ offlineCourseId ? '编辑课程' : '创建课程' }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="115px" :rules="rules">
        <el-form-item label="课程名称" prop="offlineCourseInfo.courseName">
          <el-input
            v-model="form.offlineCourseInfo.courseName"
            placeholder="请输入4-12个汉字课程名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="课程时长" prop="offlineCourseInfo.courseDuration">
          <el-input
            v-model="form.offlineCourseInfo.courseDuration"
            style="width:120px"
            oninput="value=value.replace(/[^\d.]/g,'')"
            placeholder="请输入课程时长"
            clearable
          ></el-input>
          <span style="margin-left:20px">天</span>
        </el-form-item>
        <el-form-item label="授课讲师" prop="teacherId">
          <el-input
            v-model="teacherName"
            placeholder="请输入授课讲师"
            disabled
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item ref="coursePic" label="课程封面图" prop="offlineCourseInfo.coursePic">
          <upload-image
            v-model="form.offlineCourseInfo.coursePic"
            :upload-img="$refs.coursePic"
          ></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <el-form-item label="课程简介" prop="offlineCourseInfo.courseDesc">
          <el-input
            v-model="form.offlineCourseInfo.courseDesc"
            type="textarea"
            :rows="5"
            placeholder="请输入课程简介"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="课程详细描述">
          <tinymce ref="editor" v-model="form.offlineCourseInfo.courseIntroduction"></tinymce>
        </el-form-item>
        <el-form-item label="授课见证名称" prop="teachingWitnessInfo.teachingWitnessName">
          <el-input
            v-model="form.teachingWitnessInfo.teachingWitnessName"
            placeholder="请输入授课见证名称(最多24个汉字)"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item ref="teachingWitnessPic" label="封面图" prop="teachingWitnessPic">
          <upload-image
            v-model="form.teachingWitnessInfo.teachingWitnessPic"
            :upload-img="$refs.teachingWitnessPic"
          ></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <el-form-item label="授课见证描述">
          <tinymce
            ref="editor"
            v-model="form.teachingWitnessInfo.teachingWitnessIntroduction"
          ></tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit">{{
            offlineCourseId ? '确认保存' : '确认创建'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { ceratedOfflineCourses, editOfflineCourses, searchOfflineCourses } from '@/api/teacher'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
import Tinymce from '@/components/Tinymce'
export default {
  components: { UploadImage, Tinymce },
  data() {
    return {
      rules: {
        'offlineCourseInfo.courseName': [
          { required: true, message: '请输入课程名称', trigger: 'blur' },
          { min: 1, max: 12, message: '请输入1~12个字', trigger: 'blur' },
        ],
        'teachingWitnessInfo.teachingWitnessName': [
          { min: 1, max: 24, message: '请输入1~24个字', trigger: 'blur' },
        ],
        'offlineCourseInfo.courseDuration': [
          { required: true, message: '请输入课程时长', trigger: 'blur' },
        ],
        // teacherName: [{ required: true, message: '请输入授课讲师', trigger: 'blur' }],
        'offlineCourseInfo.coursePic': [
          { required: true, message: '请上传课程封面图', trigger: 'change' },
        ],
        'offlineCourseInfo.courseDesc': [
          { min: 1, max: 128, message: '请输入1~128个字', trigger: 'blur' },
        ],
      },
      teacherName: this.$route.query.teacherName,
      form: {
        offlineCourseInfo: {
          teacherId: this.$route.query.teacherId,
          coursePic: '',
          courseName: '',
          courseIntroduction: '',
          courseDuration: '',
        },
        teachingWitnessInfo: {
          teachingWitnessIntroduction: '',
          teachingWitnessName: '',
          teachingWitnessPic: '',
        },
      },
      loading: false,
      offlineCourseId: '',
    }
  },
  mounted() {
    const { offlineCourseId } = this.$route.query
    this.offlineCourseId = offlineCourseId
    if (offlineCourseId) this.searchOfflineCourses()
  },
  methods: {
    async searchOfflineCourses() {
      const [res, err] = await to(searchOfflineCourses({ offlineCourseId: this.offlineCourseId }))
      if (err) return this.$message.warning(err.msg)
      this.form = res.data
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.offlineCourseId ? this.update() : this.create()
        }
      })
    },
    async create() {
      this.loading = true
      const [, err] = await to(ceratedOfflineCourses(this.form))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('创建成功')
      this.$router.push({
        path: '/teacher/offlineCoursesList',
        query: {
          teacherId: this.form.offlineCourseInfo.teacherId,
          teacherName: this.teacherName,
        },
      })
    },
    async update() {
      this.loading = true
      const [, err] = await to(editOfflineCourses(this.form))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('修改成功')
      this.$router.push({
        path: '/teacher/offlineCoursesList',
        query: {
          teacherId: this.form.offlineCourseInfo.teacherId,
          teacherName: this.teacherName,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.course-library-add {
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
}
</style>
